import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={
					"Cartesian Product of Two Sets | A Helpful Line-by-Line Code Tutorial"
				}
				subHeader={""}
				description={""}
				keywords={[]}
				embeddedVideo={"JH2EJoVKax8"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/b6c7cd20a55bb979354a008f02392004/raw/46c7fbd68ea139649df0c075d8c912df5489d9ea/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
